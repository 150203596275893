@use "src/styles/colors";
@use "src/styles/vars";

.berg-pages-qr-ticket {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  background: colors.$white;

  header {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 38px;
    background: colors.$gray-50;
  }

  main {
    display: grid;
    gap: 32px;
    padding: 32px 25px;
    color: colors.$gray-900;
    font-size: 18px;
    width: 100%;
    max-width: 995px;
    grid-template-areas:
      "code"
      "details"
      "validity"
      "price"
      "button"
      "apps";
  }

  &__qr-code {
    grid-area: code;
  }

  &__details {
    grid-area: details;
    text-align: start;
    line-height: 36px;

    & > span {
      font-size: 20px;
    }
  }

  b {
    font-weight: 600;
  }

  &__validity {
    grid-area: validity;

    border-bottom: 1px solid colors.$gray-300;
    text-align: start;
    padding-bottom: 12px;

    div {
      display: flex;
      align-items: center;
    }

    div:nth-child(2) {
      font-size: 15px;
      font-weight: 500;
      margin-left: 30px;
    }

    b {
      margin-left: 0.5em;
    }

    &[aria-invalid="true"] {
      color: colors.$error-500;
    }

    &[aria-invalid="false"] {
      color: colors.$success-500;
    }
  }

  &__price {
    grid-area: price;
    font-weight: 500;
  }

  &__use-button {
    justify-self: center;
    border-radius: 2px;
    padding: 20px 60px;
    color: colors.$white;
    background-color: colors.$blue;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    text-align: center;

    &[aria-hidden="true"] {
      display: none;
    }
  }

  &__apps {
    grid-area: apps;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }

  footer {
    margin-top: auto;
    font-size: 12px;
    padding: 12px;
    text-align: center;

    a {
      text-decoration: none;
      font-weight: 600;
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    position: fixed;
    padding: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 347px;
    width: 292px;
    border-radius: 15px;
    background-color: colors.$off-white;
    z-index: 1;
    box-shadow: 0px 4px 4px colors.$black-alpha-20;

    strong {
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;
    }

    p {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      color: colors.$gray-600;
    }

    button {
      padding: 10px 20px;
      border-radius: 2px;
      min-width: 120px;
      text-transform: uppercase;
      color: colors.$white;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      text-align: center;

      &[aria-label="confirm"] {
        background: colors.$success-500;
      }

      &[aria-label="cancel"] {
        background: colors.$error-500;
      }
    }
  }

  &__additional-data {
    font-size: 17px;
    font-weight: 600;
    color: colors.$gray-600;
  }

  @media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
    main {
      padding: 64px 25px;
      justify-content: space-between;
      gap: 50px;
      grid-template-areas:
        "details code"
        "validity validity"
        "price apps";
    }

    &__use-button {
      display: none;
    }

    &__qr-code {
      justify-self: end;
    }

    &__price {
      justify-self: start;
      align-self: center;
    }

    footer {
      padding: 16px 12px;
    }
  }
}
