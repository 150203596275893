.berg-components-language-dropdown {
  position: relative;
  background: white;
  color: black;
  align-self: center;
}

.berg-components-language-dropdown button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  padding: 0 14px;
  gap: 15px;
}

.berg-components-language-dropdown button svg {
  pointer-events: none;
}

.berg-components-language-dropdown-list {
  display: none;
  left: 0;
  top: 28px;
  width: 100%;
  background: rgb(223 223 223 / 70%);
  color: black;
  text-align: center;
  position: absolute;
}

.berg-components-language-dropdown-list-item {
  padding: 18px;
  cursor: pointer;
  line-height: 0;

  svg {
    cursor: pointer;
  }
}

.berg-components-language-dropdown-list-item:hover {
  background-color: white;
}

.berg-components-language-dropdown-list-item:not(:last-child) {
  border-bottom: 1px solid white;
}

.berg-components-language-dropdown-list-item.selected {
  background: rgb(187 187 187);
}

.berg-components-language-dropdown-list li a {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px;
  text-decoration: none;
  white-space: nowrap;
}


.berg-components-language-dropdown-list li a:hover {
  background: #00000033;
}

.berg-components-language-dropdown-list.visible {
  display: block;
}
